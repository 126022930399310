<template>
  <div>
    <div class="mb-8">
      <ByStudentSearch :reset="resetPage" />
    </div>

    <v-data-table
      :custom-sort="() => students.data.data"
      :headers="headers"
      hide-default-footer
      :items="students.data.data"
      :loading="listLoading"
      multi-sort
      :sort-by="students.sort.fields"
      :sort-desc="students.sort.desc"
      @update:options="handleSort"
    >
      <template v-slot:[`header.action`]>
        <span class="subtitle-2 lightCarbon--text">
          {{ totalEntries }}
        </span>
      </template>

      <template v-slot:[`item.user`]="{ item }">
        <UserNameAndAvatar
          :full-name="
            [item.first_name, item.last_name].filter(Boolean).join(' ')
          "
          :url="item.photo"
        />
      </template>

      <template v-slot:[`item.submitted`]="{ item }">
        {{ item.total_submissions }} of {{ item.total_assignments }}
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          color="primary"
          small
          text
          :to="{
            name: 'ViewStudent',
            params: {
              studentId: item.user_uuid,
            },
          }"
        >
          VIEW STUDENT
        </v-btn>
      </template>

      <template v-slot:footer>
        <Paginator
          :page="students.page"
          :total="students.data.total"
          @change="handlePagination"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import ByStudentSearch from "@/components/dashboard/assignments/ByStudentSearch"
import Paginator from "@/components/shared/Paginator.vue"
import UserNameAndAvatar from "@/components/shared/UserNameAndAvatar.vue"

export default {
  name: "ByStudent",

  components: { ByStudentSearch, Paginator, UserNameAndAvatar },

  data: () => ({
    headers: [
      {
        text: "Name",
        value: "user",
        sortable: false,
      },
      {
        text: "Email Address",
        value: "email",
        sortable: false,
      },
      // { text: "Batch", value: "batch_name", sortable: false },
      { text: "Pending Reviews", value: "pending_reviews", sortable: false },
      { text: "Submitted", value: "submitted", sortable: false },
      {
        text: "",
        value: "action",
        sortable: false,
        align: "end",
      },
    ],
  }),

  computed: {
    ...mapGetters(["students", "listLoading"]),

    totalEntries() {
      const { data, page } = this.students

      return `${page * 10 > data.total ? data.total : page * 10} of ${
        data.total
      } Entries`
    },
  },

  methods: {
    ...mapActions(["getStudents"]),

    resetPage() {
      this.$store.commit("SET_STUDENT_PAGE", { page: 1 })
    },

    handleSort({ sortBy, sortDesc }) {
      this.resetPage()
      this.$store.commit("SET_STUDENT_SORTABLE_FIELDS", {
        fields: sortBy,
        desc: sortDesc,
      })
      this.getStudents()
    },

    handlePagination(newPage) {
      this.$store.commit("SET_STUDENT_PAGE", { page: newPage })
      this.getStudents()
    },
  },
}
</script>

<style lang="scss" scoped>
.email {
  word-break: break-word;
}
</style>
