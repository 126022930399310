<template>
  <v-row dense>
    <v-col md="9">
      <DebouncedTextField
        :input="handleSearchInput"
        label="Search Student"
        :value="students.searchStudent"
      />
    </v-col>
    <v-col md="3">
      <v-select
        v-model="value"
        class="header-fields"
        flat
        hide-details
        :items="gradeByChoices"
        label="Grade by"
        solo
        @change="handleRouteChange"
      />
    </v-col>
    <v-col md="6">
      <v-select
        class="header-fields"
        clearable
        :disabled="listLoading"
        flat
        hide-details
        item-text="name"
        item-value="uuid"
        :items="courseChoices"
        label="Course"
        solo
        :value="students.selectedCourse"
        @input="
          courseId => handleFilter('ADD_STUDENT_SELECTED_COURSE', courseId)
        "
      />
    </v-col>
    <!-- <v-col md="6">
      <v-select
        class="header-fields"
        clearable
        :disabled="listLoading"
        flat
        hide-details
        item-text="name"
        item-value="uuid"
        :items="batchChoices"
        label="Batch"
        solo
        :value="students.selectedBatch"
        @input="batchId => handleFilter('ADD_STUDENT_SELECTED_BATCH', batchId)"
      />
    </v-col> -->
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import DebouncedTextField from "@/components/shared/DebouncedTextField.vue"

export default {
  name: "ByStudentSearch",

  components: { DebouncedTextField },

  props: {
    reset: {
      type: Function,
      required: true,
    },
  },

  data: () => ({
    value: "by_student",
    gradeByChoices: [
      // {
      //   text: "By Batch",
      //   value: "by_batch",
      // },
      {
        text: "By Assignment",
        value: "by_assignment",
      },
      {
        text: "By Student",
        value: "by_student",
      },
    ],
  }),

  computed: {
    ...mapGetters(["students", "listLoading", "batchChoices", "courseChoices"]),
  },

  methods: {
    ...mapActions(["getStudents"]),

    handleSearchInput(text) {
      this.reset()
      this.$store.commit("ADD_SEARCH_STUDENT", text)
      this.getStudents()
    },

    handleFilter(request, value) {
      this.reset()
      this.$store.commit(request, value ? value : "")
      this.getStudents()
    },

    handleRouteChange() {
      if (this.value === "by_batch") {
        this.$router.push({
          name: "Home",
        })
      } else if (this.value === "by_student") {
        this.$router.push({
          name: "Student",
        })
      } else if (this.value === "by_assignment") {
        this.$router.push({
          name: "Assignment",
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
